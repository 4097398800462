.settings__container {
    width: 70%;
    margin: auto;
    min-height: 85vh;
    font-family: Roboto, sans-serif;
}

.settings__headline {
    text-align: center;
    font-family: Archivo Black, sans-serif;
}

.settings__addresses, .settings__payments, .settings__details {
    margin: 10px;
}

.settings__address-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.settings__address {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 30px 15px 5px 15px;
    border: 1px solid #0000001A;
    border-radius: 10px;
    min-width: calc(350px - 52px);
    max-width: calc(50% - 52px);
    flex-basis:100%;
}

.settings__paragraph {
    margin: 3px;
}

.settings__icons {
    display: flex;
    justify-content: flex-end;
}

.settings__icon-wrap {
    margin: 5px;
}

.settings__icon {
    width: 25px;
    height: 25px;
}

.settings__icon-large {
    width: 50px;
    height: 50px;
}

.settings__payments-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.settings__payment {
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: space-evenly;
    padding: 10px;
    border: 1px solid #0000001A;
    border-radius: 10px;
    align-items: flex-end;
    aspect-ratio: 1.8 / 1 ;
    flex-basis: 100%;
    min-width: calc(350px - 42px);
    max-width: calc(50% - 42px);
}

.settings__payment-p {
    align-self: center;
    letter-spacing: 8px;
    font-size: 20px;
}

.settings__details-wrap {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 30px 15px 15px 15px;
    border: 1px solid #0000001A;
    border-radius: 10px;
}

.settings__input-wrap {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5px auto;
}

.settings__input {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border-radius: 8px;
    border: 1px solid #616467;
}

.settings__input:focus {
    outline: 1px solid #4e5052;
    box-shadow: none;
}

.settings__label{
    position: absolute;
    background-color: white;
    top: 12px;
    left: 16px;
    color: grey;
    font-size: 12px;
    visibility: hidden;
    transition: 0.2s transform ease;
}

.settings__input:focus-within + .settings__label {
    transform: translateY(-15px);
    visibility: visible;
}

.settings__input:not(:placeholder-shown) + .settings__label {
    transform: translateY(-15px);
    visibility: visible;
}

.settings__input:focus-within::placeholder {
    visibility: hidden;
}

.settings__input:focus-within::-moz-placeholder {
    color: transparent;
}

.settings__icon-sm {
    width: 20px;
    height: 20px;
}

.settings__file-upload {
    font-size: 12px;
    width: 150px;
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #616467;
}

.settings__file-upload:hover {
    cursor: pointer;
}

.settings__file-p {
    margin: 0;
    height: 20px;
    display: flex;
    padding: 0 10px;
    align-items: center;
}

.settings__file-hidden {
    display: none;
}

.settings__avatar-wrap {
    display: flex;
    align-items: flex-start;
}

.settings__avatar {
    width: 140px;
    height: 140px;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #616467;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
}

.settings__avatar-img {
    width: 100%;
    text-align: center;
}

.setting__submit {
    color: #fff;
    background: rgb(127,131,135);
    background: linear-gradient(45deg, rgba(127,131,135,1) 0%, rgba(78,80,82,1) 100%);
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 8px;
}

.setting__submit:hover {
    cursor: pointer;
    background: rgb(58,60,62);
    background: linear-gradient(45deg, rgba(58,60,62,1) 0%, rgba(78,80,82,1) 100%);
}

.settings__submit-wrap {
    justify-self: end;
    align-self: end;
    display: flex;
}

.settings__button--wrap {
    margin: 0 10px;
}

.setting__last-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.settings__input--invalid {
    border: 1px solid #cc0000;
}

.settings__input--invalid  + .settings__label {
    color:red;
}

@media screen and (max-width: 1040px)  {
    .settings__container {
        width: 80%;
        min-height: 60vh;
        margin: auto;
    }
}

@media screen and (max-width: 992px)  {
    .settings__container {
        width: 95%;
        min-height: 60vh;
        margin: auto;
    }
}

@media screen and (width <= 768px) {
    .settings__container {
        width: 98%;
        min-height: 60vh;
        margin: auto;
    }

    .settings__addresses-wrap {
        display: flex;
        flex-direction: column;
    }
}