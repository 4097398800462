.home__container h4 {
    margin-left: 2%;
}

.home__banner {
    background-image: url("../../assets/images/background2.jpg");
    height: 70vh;
    width: 100vw;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home__new, .home__coming, .home__bestsellers {
    font-family: "Archivo Black", sans-serif;
    width: 100vw;
    font-weight: 400;
    font-style: normal;
    margin-top: 10vh;
}

.home__tiles {
    display: flex;
    justify-content: space-evenly;
}

.home__tile {
    font-family: "Roboto", sans-serif;
    width: 20%;
}

.home__tile:hover {
    cursor: pointer;
}

.home__tile > p {
    text-align: center;
    margin: 5px;
}

.home__image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    background-color: #F5F7F8;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.home__sneaker-image {
    width: 100%;
}

.home__coming-content {
    background-color: #6D8F6D;
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__coming-content p {
    font-size: 3.2vw;
    color: #F5F7F8;
    text-align: center;
    flex: 25%;
    padding: 5px;
}

.home__coming-image {
    max-height: 100%;
}

@media screen and (max-width: 992px)  {
    .home__tiles {
        flex-wrap: wrap;
    }

    .home__tile {
        width: 40%;
    }
}

@media screen and (max-width: 576px) {
    .home__banner {
        height: 40vh;
    }

    .home__coming-content {
        height: 20vh;
    }
    .home__tile {
        height: 30vh;
    }

    .home__tile > p {
        font-size: 0.8rem;
    }

    .home__image-wrap {
        height: 20vh;
    }
}