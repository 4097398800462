.orders__container {
    width: 70%;
    margin: auto;
    min-height: 85vh;
    display: grid;
    font-family: Roboto, sans-serif;
}

.orders__headline {
    text-align: center;
    font-family: Archivo Black, sans-serif;
}

.orders__container.empty {
    grid-template-columns: 1fr;
    justify-content: center;
    place-items: center;
    height: calc(85vh - 18px);
}

.orders__empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orders__image-empty {
    width: 200px;
}

@media screen and (max-width: 1040px)  {
    .orders__container {
        width: 80%;
        min-height: 60vh;
        margin: auto;
    }
}

@media screen and (max-width: 992px)  {
    .orders__container {
        width: 95%;
        min-height: 60vh;
        margin: auto;
    }
}

@media screen and (width <= 768px) {
    .orders__container {
        width: 98%;
        min-height: 60vh;
        margin: auto;
    }
}