.aside-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70vw;
    background-color: white;
    z-index: 2;
}

.aside-nav__content {
    display: flex;
    flex-direction: column;
}

.aside-nav__icon {
    width: 20px;
    height: auto;
    cursor: pointer;
}

.aside-nav__logo-wrap {
    margin: 40px auto 5px auto;
    width: 35%;
}

.aside-nav__underline {
    width: 75px;
    height: 1px;
    background-color: black;
    margin: auto;
}

.nav__mobile {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.nav__mobile-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 50px;
}

.nav__mobile-list a {
    text-decoration: none;
    color: black;
}

.nav__mobile-list li {
    cursor: pointer;
}

.nav__mobile-content {
    display: flex;
    justify-content: space-between;
}

.nav__mobile-p {
    margin: 0;
}

.nav__mobile-item {
    padding: 5px 30px;
}

.nav_mobile-arrow {
    width: 20px;
    height:auto;
}

.nav__search-wrap--mobile {
    display: none;
}

.nav {
    height: 50px;
    width: 100vw;
    background-color: white;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(210, 210, 210, 1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(210, 210, 210, 1);
    box-shadow: 0px 5px 20px 0px rgba(210, 210, 210, 1);
}

.nav__container {
    width: 97%;
    height: 50px;
    background-color: transparent;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__burger {
    display: none;
    cursor: pointer;
    width: 25px;
    position: relative;
    height: 25px;
    padding: 0;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    z-index: 3;
    appearance: none;
    background: transparent;
}

.nav__burger--shown {
    display: flex;
}

.line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}
.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}
.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}
.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}
.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.nav__logo {
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
}

.nav__menu {
    width: 25%;
    display: flex;
    font-weight: bold;
    list-style-type: none;
    padding: 0;
}

.nav__menu-link {
    color: black;
    text-decoration: none;
    margin: 0 5%;
}

.nav__menu-item {
    display: inline-block;
    position: relative;
}

.nav__menu-item::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #616467;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav__menu-item:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.nav__menu-item:hover {
    cursor: pointer;
}

.nav__icons {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: flex-end;
}

.nav__icons > * {
    margin: 0 10px;
}

.nav__icon {
    width: 25px;
    height: 25px;
    margin: 0;
    cursor: pointer;
}

.nav__search-container--side {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.nav__close--wrap {
    width: 97%;
    height: 50px;
    display: none;
    align-items: center;
    margin: auto;
    position: absolute;
    top: 0;
}

.nav__search-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.nav__search-input {
    width: 150px;
    height: 25px;
    padding: 5px 30px 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: #f5f6f6;
    z-index: 1;
}

.nav__search-input--side {
    width:80vw ;
    padding: 10px 30px 10px 10px;;
}

.nav__search-dropdown {
    width: 100%;
    height: 50px;
    background-color: white;
    position: fixed;
    left: 0;
}

.nav__button {
    cursor: pointer;
    width: 25px;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    appearance: none;
    background: transparent;
    position: relative;
}

.nav__button--search {
    z-index: 2;
    position: absolute;
    margin: 0 5px;
}

.nav__cart--number {
    height:15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3c3c3c;
    color: white;
}

.nav__dropdown {
    visibility: hidden;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    top:50px;
    padding: 10px 30px;
    width: 150px;
    right: 0;
    background-color: #ffffff;
    transition: visibility 0.1s ease;
    z-index: 10;
    -webkit-box-shadow: 0 4px 4px 0 #00000040;
    -moz-box-shadow: 0 4px 4px 0 #00000040;
    box-shadow: 0 4px 4px 0 #00000040;
}

.nav__button:hover + .nav__dropdown, .nav__dropdown:hover {
    visibility: visible;
}

.dropdown__link {
    color: #686D76;
    text-decoration: none;
}

.dropdown__link:hover {
    color: #34363b;
}

.dropdown__logout {
    color: #C40C0C;
}

.dropdown__logout:hover {
    color: #8b0909;
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .nav__search-wrap--mobile {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    .nav__search-wrap--hidden {
        display: none;
    }
}

@media screen and (min-width: 577px) and (max-width: 992px) {
    .nav__close--wrap {
        display: flex;
    }

    .nav__side--close {
        display: flex;
    }
}

@media screen and (max-width: 576px) {
    .nav__icons {
        width: auto;
    }

    .nav__icons > * {
        margin: 0 5px;
    }

    .nav__burger {
        display: flex;
    }

    .nav__menu {
        display: none;
    }

    .nav__button-profile {
        display: none;
    }

    .nav__button-heart {
        display: none;
    }
}