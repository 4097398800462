@import "https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.backdrop {
  z-index: 3;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.login-modal__button--close {
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.login-modal__button--close:hover {
  cursor: pointer;
}

.login-modal__icon--close {
  height: 25px;
}

.login-modal__header {
  text-align: center;
  margin-bottom: 40px;
}

.login-modal__form {
  flex-direction: column;
  align-items: center;
  height: 80%;
  display: flex;
}

.login-modal__form-wrap {
  justify-content: center;
  width: 90%;
  margin: 5px;
  display: flex;
  position: relative;
}

.login-modal__input {
  border: 1px solid #616467;
  border-radius: 8px;
  width: 100%;
  margin: 5px 0;
  padding: 15px;
}

.login-modal__input:focus {
  box-shadow: none;
  outline: 1px solid #4e5052;
}

.login-modal__label {
  color: gray;
  visibility: hidden;
  background-color: #fff;
  font-size: 12px;
  transition: transform .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.login-modal__input:focus-within + .login-modal__label, .login-modal__input:not(:placeholder-shown) + .login-modal__label {
  visibility: visible;
  transform: translateY(-15px);
}

.login-modal__input:focus-within::placeholder {
  visibility: hidden;
}

.login-modal__input:focus-within::-moz-placeholder {
  color: #0000;
}

.login-modal__input--invalid {
  border: 1px solid #c00;
}

.login-modal__input--invalid + .login-modal__label {
  color: red;
}

.login-modal__button {
  color: #fff;
  background: linear-gradient(45deg, #7f8387 0%, #4e5052 100%);
  border: none;
  border-radius: 8px;
  width: 30%;
  margin: 40px 20px 20px;
  padding: 10px;
}

.login-modal__button:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #3a3c3e 0%, #4e5052 100%);
}

.login-modal__form-wrap + p {
  margin: 0;
}

.login-modal__form > p {
  font-size: 12px;
}

.login-modal__link-text {
  text-decoration-line: underline;
}

.login-modal__link-text:hover {
  cursor: pointer;
}

.login-modal_error-info {
  color: red;
}

.login-modal__link {
  color: #00f;
  text-decoration: underline;
}

.login-modal__link:hover {
  cursor: pointer;
}

.login-modal__loader {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.login-modal__loader-off {
  visibility: hidden;
  margin-top: 20px;
}

@media screen and (max-width: 576px) {
  .login-modal__button--close {
    top: 25px;
    right: 5px;
  }
}

.register-modal {
  border-radius: 10px;
  flex-direction: column;
  display: flex;
}

.register-modal__header {
  text-align: center;
}

.register-modal__form {
  flex-direction: column;
  align-items: center;
  height: 80%;
  display: flex;
}

.register-modal__form-wrap {
  justify-content: center;
  width: 90%;
  margin: 5px;
  display: flex;
  position: relative;
}

.register-modal__input {
  border: 1px solid #616467;
  border-radius: 8px;
  width: 100%;
  margin: 5px 0;
  padding: 15px;
}

.register-modal__input--invalid {
  border: 1px solid #c00;
}

.register-modal__input:focus {
  box-shadow: none;
  outline: 1px solid #4e5052;
}

.register-modal__label {
  color: gray;
  visibility: hidden;
  background-color: #fff;
  font-size: 12px;
  transition: transform .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.register-modal__input:focus-within + .register-modal__label, .register-modal__input:not(:placeholder-shown) + .register-modal__label {
  visibility: visible;
  transform: translateY(-15px);
}

.register-modal__input:focus-within::placeholder {
  visibility: hidden;
}

.register-modal__input:focus-within::-moz-placeholder {
  color: #0000;
}

.register-modal__input--invalid + .register-modal__label {
  color: red;
}

.register-modal__button {
  color: #fff;
  background: linear-gradient(45deg, #7f8387 0%, #4e5052 100%);
  border: none;
  border-radius: 8px;
  width: 30%;
  margin: 40px 20px 20px;
  padding: 10px;
}

.register-modal__button:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #3a3c3e 0%, #4e5052 100%);
}

.register-modal__link {
  color: #00f;
  text-decoration: underline;
}

.register-modal__form > p {
  font-size: 12px;
}

.register-modal__link:hover {
  cursor: pointer;
}

.register-modal__loader {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.register-modal__loader-off {
  visibility: hidden;
  margin-top: 20px;
}

.modal {
  z-index: 10;
  background: #fff;
  border-radius: 16px;
  width: 30vw;
  margin: 0 auto;
  padding: 5%;
  position: relative;
  box-shadow: 0 5px 20px #0000000a;
}

.modal__button--close {
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal__button--close:hover {
  cursor: pointer;
}

.modal__icon--close {
  height: 25px;
}

.circle-loader {
  vertical-align: top;
  border: 1px solid #0003;
  border-left-color: #5cb85c;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: 1.2s linear infinite loader-spin;
  display: inline-block;
  position: relative;
}

.load-complete {
  border-color: #5cb85c;
  transition: border .5s ease-out;
  animation: none;
}

.checkmark {
  display: block;
}

.checkmark.draw:after {
  animation-name: checkmark;
  animation-duration: .8s;
  animation-timing-function: ease;
  transform: scaleX(-1)rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  transform-origin: 0 0;
  content: "";
  border-top: 2px solid #5cb85c;
  border-right: 2px solid #5cb85c;
  width: .5em;
  height: 1em;
  position: absolute;
  top: 1em;
  left: .4em;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
  }

  20% {
    opacity: 1;
    width: .5em;
    height: 0;
  }

  40% {
    opacity: 1;
    width: .5em;
    height: 1em;
  }

  100% {
    opacity: 1;
    width: .5em;
    height: 1em;
  }
}

@media screen and (max-width: 576px) {
  .modal {
    border-radius: 0;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 95vh;
    display: flex;
  }
}

.modal__button--close {
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal__button--close:hover {
  cursor: pointer;
}

.modal__icon--close {
  height: 25px;
}

.modal__header {
  text-align: center;
  margin-bottom: 40px;
}

.modal__form {
  flex-direction: column;
  align-items: center;
  height: 80%;
  display: flex;
}

.modal__form-wrap {
  justify-content: center;
  width: 90%;
  margin: 5px;
  display: flex;
  position: relative;
}

.modal__input {
  border: 1px solid #616467;
  border-radius: 8px;
  width: 100%;
  margin: 5px 0;
  padding: 15px;
}

.modal__input:focus {
  box-shadow: none;
  outline: 1px solid #4e5052;
}

.modal__label {
  color: gray;
  visibility: hidden;
  background-color: #fff;
  font-size: 12px;
  transition: transform .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.modal__input:focus-within + .modal__label, .modal__input:not(:placeholder-shown) + .modal__label {
  visibility: visible;
  transform: translateY(-15px);
}

.modal__input:focus-within::placeholder {
  visibility: hidden;
}

.modal__input:focus-within::-moz-placeholder {
  color: #0000;
}

.modal__input--invalid {
  border: 1px solid #c00;
}

.modal__input--invalid + .modal__label {
  color: red;
}

.modal__button {
  color: #fff;
  background: linear-gradient(45deg, #7f8387 0%, #4e5052 100%);
  border: none;
  border-radius: 8px;
  width: 30%;
  margin: 40px 20px 20px;
  padding: 10px;
}

.modal__button:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #3a3c3e 0%, #4e5052 100%);
}

.modal__form-wrap + p {
  margin: 0;
}

.modal__form > p {
  font-size: 12px;
}

.modal__link-text {
  text-decoration-line: underline;
}

.modal__link-text:hover {
  cursor: pointer;
}

.modal_error-info {
  color: red;
}

.modal__link {
  color: #00f;
  text-decoration: underline;
}

.modal__link:hover {
  cursor: pointer;
}

.modal__loader {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.modal__loader-off {
  visibility: hidden;
  margin-top: 20px;
}

@media screen and (max-width: 576px) {
  .modal__button--close {
    top: 25px;
    right: 5px;
  }
}

.aside-nav {
  z-index: 2;
  background-color: #fff;
  width: 70vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.aside-nav__content {
  flex-direction: column;
  display: flex;
}

.aside-nav__icon {
  cursor: pointer;
  width: 20px;
  height: auto;
}

.aside-nav__logo-wrap {
  width: 35%;
  margin: 40px auto 5px;
}

.aside-nav__underline {
  background-color: #000;
  width: 75px;
  height: 1px;
  margin: auto;
}

.nav__mobile {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.nav__mobile-list {
  margin-bottom: 50px;
  padding: 0;
  list-style-type: none;
}

.nav__mobile-list a {
  color: #000;
  text-decoration: none;
}

.nav__mobile-list li {
  cursor: pointer;
}

.nav__mobile-content {
  justify-content: space-between;
  display: flex;
}

.nav__mobile-p {
  margin: 0;
}

.nav__mobile-item {
  padding: 5px 30px;
}

.nav_mobile-arrow {
  width: 20px;
  height: auto;
}

.nav__search-wrap--mobile {
  display: none;
}

.nav {
  background-color: #fff;
  width: 100vw;
  height: 50px;
  box-shadow: 0 5px 20px #d2d2d2;
}

.nav__container {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  height: 50px;
  margin: auto;
  display: flex;
}

.nav__burger {
  cursor: pointer;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  padding: 0;
  display: none;
  position: relative;
}

.nav__burger--shown {
  display: flex;
}

.line {
  fill: none;
  stroke: #000;
  stroke-width: 6px;
  transition: stroke-dasharray .6s cubic-bezier(.4, 0, .2, 1), stroke-dashoffset .6s cubic-bezier(.4, 0, .2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6px;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6px;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6px;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134px;
  stroke-width: 6px;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30px;
  stroke-width: 6px;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134px;
  stroke-width: 6px;
}

.nav__logo {
  align-items: center;
  width: 100px;
  height: auto;
  display: flex;
}

.nav__menu {
  width: 25%;
  padding: 0;
  font-weight: bold;
  list-style-type: none;
  display: flex;
}

.nav__menu-link {
  color: #000;
  margin: 0 5%;
  text-decoration: none;
}

.nav__menu-item {
  display: inline-block;
  position: relative;
}

.nav__menu-item:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: #616467;
  width: 100%;
  height: 2px;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.nav__menu-item:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.nav__menu-item:hover {
  cursor: pointer;
}

.nav__icons {
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  display: flex;
}

.nav__icons > * {
  margin: 0 10px;
}

.nav__icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin: 0;
}

.nav__search-container--side {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  display: flex;
}

.nav__close--wrap {
  align-items: center;
  width: 97%;
  height: 50px;
  margin: auto;
  display: none;
  position: absolute;
  top: 0;
}

.nav__search-wrap {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__search-input {
  z-index: 1;
  background-color: #f5f6f6;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 25px;
  padding: 5px 30px 5px 10px;
}

.nav__search-input--side {
  width: 80vw;
  padding: 10px 30px 10px 10px;
}

.nav__search-dropdown {
  background-color: #fff;
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
}

.nav__button {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  position: relative;
}

.nav__button--search {
  z-index: 2;
  margin: 0 5px;
  position: absolute;
}

.nav__cart--number {
  color: #fff;
  background-color: #3c3c3c;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
}

.nav__dropdown {
  visibility: hidden;
  z-index: 10;
  background-color: #fff;
  width: 150px;
  margin: 0;
  padding: 10px 30px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: visibility .1s;
  position: absolute;
  top: 50px;
  right: 0;
  box-shadow: 0 4px 4px #00000040;
}

.nav__button:hover + .nav__dropdown, .nav__dropdown:hover {
  visibility: visible;
}

.dropdown__link {
  color: #686d76;
  text-decoration: none;
}

.dropdown__link:hover {
  color: #34363b;
}

.dropdown__logout {
  color: #c40c0c;
}

.dropdown__logout:hover {
  color: #8b0909;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .nav__search-wrap--mobile {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    position: relative;
  }

  .nav__search-wrap--hidden {
    display: none;
  }
}

@media screen and (min-width: 577px) and (max-width: 992px) {
  .nav__close--wrap, .nav__side--close {
    display: flex;
  }
}

@media screen and (max-width: 576px) {
  .nav__icons {
    width: auto;
  }

  .nav__icons > * {
    margin: 0 5px;
  }

  .nav__burger {
    display: flex;
  }

  .nav__menu, .nav__button-profile, .nav__button-heart {
    display: none;
  }
}

.home__container h4 {
  margin-left: 2%;
}

.home__banner {
  background-image: url("background2.b9dd666e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 70vh;
}

.home__new, .home__coming, .home__bestsellers {
  width: 100vw;
  margin-top: 10vh;
  font-family: Archivo Black, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.home__tiles {
  justify-content: space-evenly;
  display: flex;
}

.home__tile {
  width: 20%;
  font-family: Roboto, sans-serif;
}

.home__tile:hover {
  cursor: pointer;
}

.home__tile > p {
  text-align: center;
  margin: 5px;
}

.home__image-wrap {
  background-color: #f5f7f8;
  justify-content: center;
  align-items: center;
  height: 40vh;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

.home__sneaker-image {
  width: 100%;
}

.home__coming-content {
  background-color: #6d8f6d;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  display: flex;
}

.home__coming-content p {
  color: #f5f7f8;
  text-align: center;
  flex: 25%;
  padding: 5px;
  font-size: 3.2vw;
}

.home__coming-image {
  max-height: 100%;
}

@media screen and (max-width: 992px) {
  .home__tiles {
    flex-wrap: wrap;
  }

  .home__tile {
    width: 40%;
  }
}

@media screen and (max-width: 576px) {
  .home__banner {
    height: 40vh;
  }

  .home__coming-content {
    height: 20vh;
  }

  .home__tile {
    height: 30vh;
  }

  .home__tile > p {
    font-size: .8rem;
  }

  .home__image-wrap {
    height: 20vh;
  }
}

.profile {
  flex-direction: column;
  justify-content: center;
  width: 70%;
  min-height: 900px;
  margin: auto;
  display: flex;
}

.profile__dashboard-wrap {
  justify-content: space-between;
  height: 400px;
  display: flex;
}

.profile__avatar {
  background-color: #6d8f6d;
  border-radius: 10px;
  width: 38%;
}

.avatar__image-wrap {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-self: center;
  width: 20vw;
  height: 20vw;
  margin: 30px auto 0;
  display: flex;
}

.profile__avatar-img {
  width: 15vw;
}

.profile__name {
  color: #fff;
  text-align: center;
  font-family: Archivo Black, sans-serif;
  font-size: 25px;
}

.profile__action-box {
  background-color: #f5f7f8;
  border-radius: 10px;
  width: 58%;
}

.profile__with-us {
  text-align: center;
  margin-top: 0;
  font-size: 15px;
}

.profile__last-orders {
  background-color: #f5f7f8;
  border-radius: 10px;
  width: 100%;
  height: 300px;
  margin-top: 4%;
}

.margin-bottom {
  margin-bottom: 5px;
}

.profile__headline {
  text-align: center;
  font-family: Archivo Black, sans-serif;
  font-size: 25px;
}

.profile__menu-wrap {
  padding: 10px 40px;
}

.profile__menu-item {
  font-size: 18px;
  display: flex;
}

.profile__menu-img {
  width: 25px;
  padding-right: 50px;
}

.profile__menu-link {
  color: #000;
  text-decoration: none;
}

@media (max-width: 800px) {
  .profile {
    width: 90%;
    margin: 30px auto;
  }

  .profile__dashboard-wrap {
    flex-direction: column;
    height: auto;
  }

  .profile__avatar, .profile__action-box, .profile__last-orders {
    width: 100%;
    margin-top: 20px;
  }

  .avatar__image-wrap {
    width: 35vw;
    height: 35vw;
  }
}

@media (min-width: 1500px) {
  .avatar__image-wrap {
    width: 16vw;
    height: 16vw;
  }

  .profile__avatar-img {
    width: 10vw;
  }
}

:root {
  --grey: #616467;
}

.container {
  margin: 0 auto;
}

.collapsible {
  grid-row-gap: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.collapsible__content {
  max-height: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  transition: max-height .3s;
  overflow: hidden;
}

.collapsible--expanded {
  max-height: 100px;
}

.collapsible__header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.collapsible__header > h4 {
  margin: 0;
}

.collapsible__collapse-arrow {
  width: 15px;
  padding-right: 10px;
}

.collapsible__collapse-arrow--down {
  padding-left: 10px;
  transition: all .3s;
  transform: rotate(180deg);
}

.collapsible__collapse-arrow--up {
  padding-left: 10px;
  transition: all .3s;
  transform: rotate(0);
}

.footer-mobile {
  background-color: #f5f7f8;
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
}

.footer-mobile__list {
  flex-direction: column;
  margin: 0;
  padding-left: 10px;
  list-style-type: none;
}

.footer-mobile__form {
  display: flex;
}

.footer-mobile__form input {
  border: none;
  border-radius: 8px 0 0 8px;
  width: 30vw;
  padding: 8px;
  box-shadow: 0 10px 20px #0000001a, 0 3px 6px #0000000d;
}

.footer-mobile__form input:focus {
  outline: none;
}

.footer-mobile__form button {
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(#464d55, #25292e);
  border: none;
  border-radius: 0 8px 8px 0;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  transition: all .15s;
  display: flex;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 10px 20px #0000001a, 0 3px 6px #0000000d;
}

.footer-mobile__button-icon {
  width: 15px;
}

.footer-mobile__form button:hover {
  opacity: .85;
  box-shadow: 0 2px 8px #00010033;
}

.footer-mobile__form button:active {
  outline: 0;
}

.footer-mobile__form button:focus {
  box-shadow: 0 0 0 3px #00000080;
}

.footer-mobile__socials {
  display: flex;
}

.footer-mobile__socials img {
  width: 25px;
  margin: 0 10px;
}

.footer-mobile__payments {
  display: flex;
}

.footer-mobile__payments img {
  max-width: 50px;
  margin: 0 5px;
}

.footer {
  background-color: #f5f7f8;
  width: 100vw;
  margin-top: 10vh;
  font-size: 14px;
  font-weight: 500;
}

.footer__main-row {
  justify-content: space-evenly;
  display: flex;
}

.footer__main-row div {
  width: 15%;
}

.footer__list {
  padding-left: 0;
  list-style-type: none;
}

.footer__socials {
  display: flex;
}

.footer__socials img {
  width: 25px;
  margin: 10%;
}

.footer__form form {
  display: flex;
}

.footer__form input {
  border: none;
  border-radius: 8px 0 0 8px;
  width: 10vw;
  padding: 8px;
  box-shadow: 0 10px 20px #0000001a, 0 3px 6px #0000000d;
}

.footer__form input:focus {
  outline: none;
}

.footer__form button {
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(#464d55, #25292e);
  border: none;
  border-radius: 0 8px 8px 0;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  transition: all .15s;
  display: flex;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 10px 20px #0000001a, 0 3px 6px #0000000d;
}

.footer__form button:hover {
  opacity: .85;
  box-shadow: 0 2px 8px #00010033;
}

.footer__form button:active {
  outline: 0;
}

.footer__form button:focus {
  box-shadow: 0 0 0 3px #00000080;
}

.footer__button-icon {
  width: 15px;
}

.footer__payments {
  width: 84%;
  margin: auto;
}

.footer__payments-icons {
  width: 100%;
  display: flex;
}

.footer__payments-icons img {
  max-width: 50px;
  margin: 0 5px;
}

.favourites__image-empty {
  width: 200px;
}

.favourites {
  width: 70%;
  min-height: 85vh;
  margin: auto;
}

.favourites__headline {
  text-align: center;
  font-family: Archivo Black, serif;
}

.favourites__container {
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  width: 100%;
  display: grid;
}

.favourites__container.empty {
  grid-template-columns: 1fr;
  justify-content: center;
  place-items: center;
  height: calc(85vh - 18px);
}

.favourites__empty-content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.favourites__item {
  width: 20vw;
  height: 20vw;
  margin: 30px 0;
}

.favourites__item:hover {
  cursor: pointer;
}

.favourites__desc {
  text-align: center;
  margin: 5px 0;
}

.favourites__image-wrap {
  background-color: #f5f7f8;
  justify-content: center;
  width: 20vw;
  height: 15vw;
  display: flex;
  position: relative;
  box-shadow: 0 7px 29px #64646f33;
}

.favourites__image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.favourites__icon-heart {
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

.favourites__heart {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 768px) {
  .favourites {
    width: 90%;
  }

  .favourites__container {
    justify-content: space-evenly;
  }

  .favourites__item {
    width: 40vw;
    height: 40vw;
  }

  .favourites__desc {
    font-size: .8rem;
  }

  .favourites__image-wrap {
    width: 40vw;
    height: 35vw;
  }
}

@media screen and (max-width: 1200px) {
  .favourites {
    width: 80%;
  }

  .favourites__container {
    justify-content: space-evenly;
  }

  .favourites__item {
    width: 25vw;
    height: 25vw;
  }

  .favourites__image-wrap {
    width: 25vw;
    height: 20vw;
  }
}

@media screen and (max-width: 992px) {
  .favourites {
    width: 80%;
  }

  .favourites__container {
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }

  .favourites__item {
    width: 30vw;
    height: 30vw;
  }

  .favourites__image-wrap {
    width: 30vw;
    height: 25vw;
  }
}

@media screen and (max-width: 768px) {
  .favourites {
    width: 90%;
  }

  .favourites__container {
    justify-content: space-evenly;
  }

  .favourites__item {
    width: 40vw;
    height: 40vw;
  }

  .favourites__desc {
    font-size: .8rem;
  }

  .favourites__image-wrap {
    width: 40vw;
    height: 35vw;
  }
}

@media screen and (max-width: 576px) {
  .favourites {
    width: 98%;
  }

  .favourites__container {
    justify-content: space-evenly;
  }

  .favourites__item {
    width: 45vw;
    height: 45vw;
  }

  .favourites__image-wrap {
    width: 45vw;
    height: 40vw;
  }
}

.collection {
  width: 70%;
  min-height: 85vh;
  margin: auto;
}

.collection__headline {
  text-align: center;
  font-family: Archivo Black, serif;
}

.collection__container {
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  width: 100%;
  display: grid;
}

.collection__item {
  width: 20vw;
  height: 20vw;
  margin: 30px 0;
}

.collection__item:hover {
  cursor: pointer;
}

.collection__desc {
  text-align: center;
  margin: 5px 0;
}

.collection__image-wrap {
  background-color: #f5f7f8;
  justify-content: center;
  width: 20vw;
  height: 15vw;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

.collection__image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1200px) {
  .collection {
    width: 80%;
  }

  .collection__container {
    justify-content: space-evenly;
  }

  .collection__item {
    width: 25vw;
    height: 25vw;
  }

  .collection__image-wrap {
    width: 25vw;
    height: 20vw;
  }
}

@media screen and (max-width: 992px) {
  .collection {
    width: 80%;
  }

  .collection__container {
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }

  .collection__item {
    width: 30vw;
    height: 30vw;
  }

  .collection__image-wrap {
    width: 30vw;
    height: 25vw;
  }
}

@media screen and (max-width: 768px) {
  .collection {
    width: 90%;
  }

  .collection__container {
    justify-content: space-evenly;
  }

  .collection__item {
    width: 40vw;
    height: 40vw;
  }

  .collection__desc {
    font-size: .8rem;
  }

  .collection__image-wrap {
    width: 40vw;
    height: 35vw;
  }
}

@media screen and (max-width: 576px) {
  .collection {
    width: 98%;
  }

  .collection__container {
    justify-content: space-evenly;
  }

  .collection__item {
    width: 45vw;
    height: 45vw;
  }

  .collection__image-wrap {
    width: 45vw;
    height: 40vw;
  }
}

.collection__container--override {
  justify-content: center;
  align-items: center;
  height: calc(85vh - 18px);
  display: flex;
}

.search {
  width: 70%;
  min-height: 85vh;
  margin: auto;
}

.search__headline {
  text-align: center;
  font-family: Archivo Black, serif;
}

.search__container {
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  width: 100%;
  display: grid;
}

.search__item {
  width: 20vw;
  height: 20vw;
  margin: 30px 0;
}

.search__item:hover {
  cursor: pointer;
}

.search__desc {
  text-align: center;
  margin: 5px 0;
}

.search__image-wrap {
  background-color: #f5f7f8;
  justify-content: center;
  width: 20vw;
  height: 15vw;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

.search__image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1200px) {
  .search {
    width: 80%;
  }

  .search__container {
    justify-content: space-evenly;
  }

  .search__item {
    width: 25vw;
    height: 25vw;
  }

  .search__image-wrap {
    width: 25vw;
    height: 20vw;
  }
}

@media screen and (max-width: 992px) {
  .search {
    width: 80%;
  }

  .search__container {
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }

  .search__item {
    width: 30vw;
    height: 30vw;
  }

  .search__image-wrap {
    width: 30vw;
    height: 25vw;
  }
}

@media screen and (max-width: 768px) {
  .search {
    width: 90%;
  }

  .search__container {
    justify-content: space-evenly;
  }

  .search__item {
    width: 40vw;
    height: 40vw;
  }

  .search__desc {
    font-size: .8rem;
  }

  .search__image-wrap {
    width: 40vw;
    height: 35vw;
  }
}

@media screen and (max-width: 576px) {
  .search {
    width: 98%;
  }

  .search__container {
    justify-content: space-evenly;
  }

  .search__item {
    width: 45vw;
    height: 45vw;
  }

  .search__image-wrap {
    width: 45vw;
    height: 40vw;
  }
}

.search__container--override {
  grid-template-columns: 1fr;
  height: calc(85vh - 18px);
}

.cart {
  width: 70%;
  min-height: 85vh;
  margin: auto;
  font-family: Roboto, sans-serif;
}

.cart__headline-main, .cart__headline {
  text-align: center;
  font-family: Archivo Black, sans-serif;
}

.cart__container {
  justify-content: space-between;
  display: flex;
}

.cart__items {
  flex-direction: column;
  flex-basis: 60%;
  align-content: flex-start;
  margin: 10px;
  font-family: Roboto, sans-serif;
  display: flex;
}

.cart__item {
  border: 1px solid #0000001a;
  border-radius: 10px;
  align-items: center;
  height: 125px;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.cart__remove-button {
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
}

.cart__remove-button:hover {
  cursor: pointer;
}

.cart__remove-icon {
  width: 15px;
  height: 15px;
}

.cart__item-image {
  flex-basis: 30%;
}

.cart__item-preview {
  width: 90%;
}

.cart__delimiter {
  background-color: #00000080;
  align-self: center;
  width: 1px;
  height: 60%;
  margin: 0 10px;
}

.cart__description {
  flex-basis: 45%;
  height: 60px;
}

.cart__description-bold {
  margin: 0 0 4px;
  font-weight: 500;
}

.cart__description-small {
  margin: 4px 0;
  font-size: 13px;
  font-weight: 300;
}

.cart__item-price {
  flex-direction: column;
  flex-basis: 25%;
  justify-content: space-between;
  height: 60px;
  display: flex;
}

.cart__quantity {
  text-align: center;
  background-color: #d9d9d94d;
  border: none;
  align-self: flex-end;
  padding: 3px;
}

.cart__quantity:hover {
  cursor: pointer;
}

.cart__summary {
  background-color: #f5f7f8;
  border-radius: 10px;
  flex-basis: 30%;
  height: 350px;
  margin-top: 20px;
}

.cart__summary-wrap {
  padding: 20px;
}

.cart__price-wrap {
  justify-content: space-between;
  display: flex;
}

.cart__price {
  margin: 2px 0;
  font-weight: 300;
}

.cart__price-paragraph {
  align-self: flex-end;
  margin: 0;
}

.cart__price-bold {
  margin: 20px 0;
  font-weight: 500;
}

.cart__summary-headline {
  text-align: center;
  font-family: Archivo Black, sans-serif;
}

.cart__promo-wrap {
  margin-top: 15px;
}

.cart__promo {
  margin: 0 2px;
  font-size: 12px;
}

.cart__promo-form {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cart__promo-button {
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(#464d55, #25292e);
  border: none;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  padding: 6px;
  transition: all .15s;
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 10px 20px #0000001a, 0 3px 6px #0000000d;
}

.cart__promo-button:hover {
  opacity: .85;
  box-shadow: 0 2px 8px #00010033;
}

.cart__input {
  border: 1px solid #616467;
  border-radius: 8px;
  width: 70%;
  margin: 5px 0;
  padding: 5px;
}

.cart__button-submit {
  color: #fff;
  background: linear-gradient(345deg, #749f74 0%, #5e7a5e 35%, #4c654c 68%, #354435 100%);
  border: none;
  border-radius: 10px;
  margin: 40px auto 0;
  padding: 10px;
  font-size: 20px;
  display: block;
}

.cart__button-submit:hover {
  cursor: pointer;
  opacity: .9;
  background-color: #6d8f6d;
}

.cart__empty-wrap {
  width: 200px;
  margin: 100px auto 0;
}

@media screen and (max-width: 992px) {
  .cart {
    width: 95%;
  }

  .cart__summary {
    flex-basis: 35%;
  }

  .cart__item-preview {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .cart {
    width: 98%;
    min-height: 60vh;
  }

  .cart__container {
    flex-wrap: wrap;
  }

  .cart__items {
    flex-basis: 100%;
  }

  .cart__summary {
    background-color: #0000;
    flex-basis: 100%;
  }

  .cart__item-preview {
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .cart__item-preview {
    width: 100%;
  }
}

.detailed {
  width: 70vw;
  min-height: 85vh;
  margin: auto;
  font-family: Roboto, sans-serif;
}

.detailed__content {
  justify-content: space-evenly;
  margin-top: 100px;
  display: flex;
}

.detailed__content--override {
  justify-content: center;
  align-items: center;
  height: calc(85vh - 100px);
}

.detailed__image-wrap {
  background-color: #f5f7f8;
  flex-basis: 45%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.detailed__info-wrap {
  width: 300px;
}

.detailed__name {
  margin-top: 0;
  margin-bottom: 5px;
}

.detailed__price-tag {
  margin: 5px 0;
  font-size: 20px;
}

.detailed__color-tag {
  margin: 5px 0;
  font-size: 15px;
  font-weight: 300;
}

.detailed__image {
  width: 100%;
}

.detailed__size-desc {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.detailed__size-desc > p {
  margin-bottom: 10px;
  font-size: 18px;
}

.detailed__size-table {
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  display: grid;
}

.detailed__size-item {
  border: 1px solid #0003;
  border-radius: 5px;
  padding: 5px;
}

.detailed__size-item--active {
  border: 1px solid #000;
}

.detailed__size-item:hover {
  cursor: pointer;
  border: 1px solid #000;
}

.detailed__buttons-wrap {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.detailed__favourites {
  color: #000;
  background-color: #0000;
  border: 1px solid #0003;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 140px;
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.detailed__favourites:hover {
  cursor: pointer;
  border: 1px solid #000;
}

.detailed__favourites-active {
  background-color: #000;
}

.detailed__button-basket {
  color: #fff;
  background-color: #6d8f6d;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 140px;
  padding: 10px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.detailed__button-icon {
  width: 25px;
  margin-left: 10px;
}

.detailed__button-basket:hover {
  cursor: pointer;
  opacity: .9;
}

.detailed__button-basket--active {
  transition: transform .5s;
  animation: .5s alternate heartAnimation;
}

.filter-white {
  filter: invert() sepia(0%) saturate(0%) hue-rotate(225deg) brightness(101%) contrast(102%);
}

.filter-red {
  filter: invert(15%) sepia(99%) saturate(3785%) hue-rotate(357deg) brightness(95%) contrast(95%);
  fill: red;
  transition: transform .5s;
  animation: .5s alternate heartAnimation;
}

@keyframes heartAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) {
  .detailed {
    width: 98vw;
  }
}

@media screen and (max-width: 576px) {
  .detailed {
    display: flex;
  }

  .detailed__content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .detailed__image-wrap {
    flex-basis: 100%;
  }
}

.settings__container {
  width: 70%;
  min-height: 85vh;
  margin: auto;
  font-family: Roboto, sans-serif;
}

.settings__headline {
  text-align: center;
  font-family: Archivo Black, sans-serif;
}

.settings__addresses, .settings__payments, .settings__details {
  margin: 10px;
}

.settings__address-wrap {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.settings__address {
  border: 1px solid #0000001a;
  border-radius: 10px;
  flex-direction: column;
  flex-basis: 100%;
  min-width: 298px;
  max-width: calc(50% - 52px);
  margin: 10px;
  padding: 30px 15px 5px;
  display: flex;
}

.settings__paragraph {
  margin: 3px;
}

.settings__icons {
  justify-content: flex-end;
  display: flex;
}

.settings__icon-wrap {
  margin: 5px;
}

.settings__icon {
  width: 25px;
  height: 25px;
}

.settings__icon-large {
  width: 50px;
  height: 50px;
}

.settings__payments-wrap {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.settings__payment {
  aspect-ratio: 1.8;
  border: 1px solid #0000001a;
  border-radius: 10px;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
  min-width: 308px;
  max-width: calc(50% - 42px);
  margin: 10px;
  padding: 10px;
  display: flex;
}

.settings__payment-p {
  letter-spacing: 8px;
  align-self: center;
  font-size: 20px;
}

.settings__details-wrap {
  border: 1px solid #0000001a;
  border-radius: 10px;
  flex-direction: column;
  margin: 10px 0;
  padding: 30px 15px 15px;
  display: flex;
}

.settings__input-wrap {
  justify-content: center;
  width: 100%;
  margin: 5px auto;
  display: flex;
  position: relative;
}

.settings__input {
  border: 1px solid #616467;
  border-radius: 8px;
  width: 100%;
  margin: 5px 0;
  padding: 15px;
}

.settings__input:focus {
  box-shadow: none;
  outline: 1px solid #4e5052;
}

.settings__label {
  color: gray;
  visibility: hidden;
  background-color: #fff;
  font-size: 12px;
  transition: transform .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.settings__input:focus-within + .settings__label, .settings__input:not(:placeholder-shown) + .settings__label {
  visibility: visible;
  transform: translateY(-15px);
}

.settings__input:focus-within::placeholder {
  visibility: hidden;
}

.settings__input:focus-within::-moz-placeholder {
  color: #0000;
}

.settings__icon-sm {
  width: 20px;
  height: 20px;
}

.settings__file-upload {
  border: 1px solid #616467;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 11px;
  font-size: 12px;
  display: flex;
}

.settings__file-upload:hover {
  cursor: pointer;
}

.settings__file-p {
  align-items: center;
  height: 20px;
  margin: 0;
  padding: 0 10px;
  display: flex;
}

.settings__file-hidden {
  display: none;
}

.settings__avatar-wrap {
  align-items: flex-start;
  display: flex;
}

.settings__avatar {
  border: 1px solid #616467;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  margin: 0 10px 0 0;
  padding: 10px;
  display: flex;
}

.settings__avatar-img {
  text-align: center;
  width: 100%;
}

.setting__submit {
  color: #fff;
  background: linear-gradient(45deg, #7f8387 0%, #4e5052 100%);
  border: none;
  border-radius: 8px;
  width: 100px;
  padding: 10px;
}

.setting__submit:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #3a3c3e 0%, #4e5052 100%);
}

.settings__submit-wrap {
  place-self: end;
  display: flex;
}

.settings__button--wrap {
  margin: 0 10px;
}

.setting__last-row {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.settings__input--invalid {
  border: 1px solid #c00;
}

.settings__input--invalid + .settings__label {
  color: red;
}

@media screen and (max-width: 1040px) {
  .settings__container {
    width: 80%;
    min-height: 60vh;
    margin: auto;
  }
}

@media screen and (max-width: 992px) {
  .settings__container {
    width: 95%;
    min-height: 60vh;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .settings__container {
    width: 98%;
    min-height: 60vh;
    margin: auto;
  }

  .settings__addresses-wrap {
    flex-direction: column;
    display: flex;
  }
}

.orders__container {
  width: 70%;
  min-height: 85vh;
  margin: auto;
  font-family: Roboto, sans-serif;
  display: grid;
}

.orders__headline {
  text-align: center;
  font-family: Archivo Black, sans-serif;
}

.orders__container.empty {
  grid-template-columns: 1fr;
  justify-content: center;
  place-items: center;
  height: calc(85vh - 18px);
}

.orders__empty-content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.orders__image-empty {
  width: 200px;
}

@media screen and (max-width: 1040px) {
  .orders__container {
    width: 80%;
    min-height: 60vh;
    margin: auto;
  }
}

@media screen and (max-width: 992px) {
  .orders__container {
    width: 95%;
    min-height: 60vh;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .orders__container {
    width: 98%;
    min-height: 60vh;
    margin: auto;
  }
}

/*# sourceMappingURL=index.b1deea3f.css.map */
