.footer {
    background-color: #F5F7F8;
    width: 100vw;
    margin-top: 10vh;
    font-weight: 500;
    font-size: 14px;
}

.footer__main-row {
    display: flex;
    justify-content: space-evenly;
}

.footer__main-row div {
    width: 15%;
}

.footer__list {
    list-style-type: none;
    padding-left: 0;
}

.footer__socials {
    display: flex;
}

.footer__socials img {
    width: 25px;
    margin: 10%;
}

.footer__form form {
    display: flex;
}

.footer__form input {
    width: 10vw;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    padding: 8px;
    border: none;
}

.footer__form input:focus {
    outline: none;
}

.footer__form button {
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 0 8px 8px 0;
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
}

.footer__form button:hover {
    box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
    opacity: .85;
}

.footer__form button:active {
    outline: 0;
}

.footer__form button:focus {
    box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

.footer__button-icon {
    width: 15px;
}

.footer__payments {
    width: 84%;
    margin: auto;
}

.footer__payments-icons {
    display: flex;
    width: 100%;
}

.footer__payments-icons img {
    max-width: 50px;
    margin: 0 5px;
}