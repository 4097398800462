.modal {
    padding: 5%;
    width: clamp(25vw,30vw,40vw);
    background: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    margin: 0 auto;
    position: relative;
}

.modal__button--close {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 20px;
    right:20px;
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__button--close:hover {
    cursor: pointer;
}

.modal__icon--close {
    height: 25px;
}

.circle-loader {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #5cb85c;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 2em;
    height: 2em;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #5cb85c;
    transition: border 500ms ease-out;
}

.checkmark {
    display: block;
}

.checkmark.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
    opacity: 1;
    height: 1.0em;
    width: 0.5em;
    transform-origin: left top;
    border-right: 2px solid #5cb85c;
    border-top: 2px solid #5cb85c;
    content: "";
    left: 0.4em;
    top: 1em;
    position: absolute;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 0.5em;
        opacity: 1;
    }
    40% {
        height: 1.0em;
        width: 0.5em;
        opacity: 1;
    }
    100% {
        height: 1.0em;
        width: 0.5em;
        opacity: 1;
    }
}

@media screen and (max-width: 576px) {
    .modal {
        width: 100vw;
        height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0;
    }
}