.footer-mobile {
    background-color: #F5F7F8;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    margin-top: 50px;
    padding: 10px 0;
}

.footer-mobile__list {
    list-style-type: none;
    padding-left: 10px;
    flex-direction: column;
    margin: 0;
}

.footer-mobile__form {
    display: flex;
}

.footer-mobile__form input {
    width: 30vw;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    padding: 8px;
    border: none;
}

.footer-mobile__form input:focus {
    outline: none;
}

.footer-mobile__form button {
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 0 8px 8px 0;
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
}

.footer-mobile__button-icon {
    width: 15px;
}

.footer-mobile__form button:hover {
    box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
    opacity: .85;
}

.footer-mobile__form button:active {
    outline: 0;
}

.footer-mobile__form button:focus {
    box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

.footer-mobile__socials {
    display: flex;
}

.footer-mobile__socials img {
    width: 25px;
    margin: 0 10px;
}

.footer-mobile__payments {
    display: flex;
}

.footer-mobile__payments img {
    max-width: 50px;
    margin: 0 5px;
}