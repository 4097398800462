.cart {
    width: 70%;
    margin: auto;
    min-height: 85vh;
    font-family: Roboto, sans-serif;
}

.cart__headline-main {
    font-family: "Archivo Black",sans-serif;
    text-align: center;
}

.cart__headline {
    font-family: "Archivo Black",sans-serif;
    text-align: center;
}

.cart__container {
    display: flex;
    justify-content: space-between;
}

.cart__items {
    flex-basis: 60%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    font-family: Roboto, sans-serif;
}

.cart__item {
    border: 1px solid #0000001A;
    border-radius: 10px;
    height: 125px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;
}

.cart__remove-button {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
}

.cart__remove-button:hover {
    cursor: pointer;
}

.cart__remove-icon {
    width: 15px;
    height: 15px;
}

.cart__item-image {
    flex-basis: 30%;
}

.cart__item-preview {
    width: 90%;
}

.cart__delimiter {
    width: 1px;
    background-color: #00000080;
    height: 60%;
    align-self: center;
    margin: 0 10px;
}

.cart__description {
    flex-basis: 45%;
    height: 60px;
}

.cart__description-bold {
    font-weight: 500;
    margin: 0 0 4px 0;
}

.cart__description-small {
    font-weight: 300;
    font-size: 13px;
    margin: 4px 0;
}

.cart__item-price {
    flex-basis: 25%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart__quantity {
    background-color: #D9D9D94D;
    border: none;
    padding: 3px;
    align-self: flex-end;
    text-align: center;
}

.cart__quantity:hover {
    cursor: pointer;
}

.cart__summary {
    flex-basis: 30%;
    background-color: #F5F7F8;
    height: 350px;
    margin-top: 20px;
    border-radius: 10px;
}

.cart__summary-wrap {
    padding: 20px;
}

.cart__price-wrap {
    display: flex;
    justify-content: space-between;
}

.cart__price {
    font-weight: 300;
    margin: 2px 0;
}

.cart__price-paragraph {
    margin: 0;
    align-self: flex-end;
}

.cart__price-bold {
    font-weight: 500;
    margin: 20px 0;
}

.cart__summary-headline {
    font-family: "Archivo Black",sans-serif;
    text-align: center;
}

.cart__promo-wrap {
    margin-top: 15px;
}

.cart__promo {
    font-size: 12px;
    margin: 0 2px;
}

.cart__promo-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__promo-button {
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    border: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    position: relative;
}

.cart__promo-button:hover {
    box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
    opacity: .85;
}
.cart__input {
    padding: 5px;
    margin: 5px 0;
    border-radius: 8px;
    border: 1px solid #616467;
    width: 70%;
}

.cart__button-submit {
    padding: 10px;
    font-size: 20px;
    color: white;
    background: rgb(116,159,116);
    background: linear-gradient(345deg, rgba(116,159,116,1) 0%, rgba(94,122,94,1) 35%, rgba(76,101,76,1) 68%, rgba(53,68,53,1) 100%);
    border-radius: 10px;
    border: none;
    margin: 40px auto 0;
    display: block;
}

.cart__button-submit:hover {
    background-color: #6D8F6D;
    cursor: pointer;
    opacity: .90;
}

.cart__empty-wrap {
    margin: 100px auto 0;
    width: 200px;
}

@media screen and (max-width: 992px)  {
    .cart {
        width: 95%;
    }

    .cart__summary {
        flex-basis: 35%;
    }

    .cart__item-preview {
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .cart {
        min-height: 60vh;
            width: 98%;
    }
    .cart__container {
        flex-wrap: wrap;
    }

    .cart__items {
        flex-basis: 100%;
    }

    .cart__summary {
        flex-basis: 100%;
        background-color: transparent;
    }

    .cart__item-preview {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .cart__item-preview {
        width: 100%;
    }
}