.login-modal__button--close {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 20px;
    right:20px;
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-modal__button--close:hover {
    cursor: pointer;
}

.login-modal__icon--close {
    height: 25px;
}

.login-modal__header {
    text-align: center;
    margin-bottom: 40px;
}

.login-modal__form {
    display: flex;
    height: 80%;
    flex-direction: column;
    align-items: center;
}

.login-modal__form-wrap {
    width: 90%;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5px;
}

.login-modal__input {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border-radius: 8px;
    border: 1px solid #616467;
}

.login-modal__input:focus {
    outline: 1px solid #4e5052;
    box-shadow: none;
}

.login-modal__label{
    position: absolute;
    background-color: white;
    top: 12px;
    left: 16px;
    color: grey;
    font-size: 12px;
    visibility: hidden;
    transition: 0.2s transform ease;
}

.login-modal__input:focus-within + .login-modal__label {
    transform: translateY(-15px);
    visibility: visible;
}

.login-modal__input:not(:placeholder-shown) + .login-modal__label {
    transform: translateY(-15px);
    visibility: visible;
}

.login-modal__input:focus-within::placeholder {
    visibility: hidden;
}

.login-modal__input:focus-within::-moz-placeholder {
    color: transparent;
}

.login-modal__input--invalid {
    border: 1px solid #cc0000;
}

.login-modal__input--invalid + .login-modal__label {
    color:red;
}

.login-modal__button {
    color: #fff;
    background: rgb(127,131,135);
    background: linear-gradient(45deg, rgba(127,131,135,1) 0%, rgba(78,80,82,1) 100%);
    width: 30%;
    margin: 40px 20px 20px 20px;
    padding: 10px;
    border: none;
    border-radius: 8px;
}

.login-modal__button:hover {
    cursor: pointer;
    background: rgb(58,60,62);
    background: linear-gradient(45deg, rgba(58,60,62,1) 0%, rgba(78,80,82,1) 100%);
}

.login-modal__form-wrap + p {
    margin: 0;
}

.login-modal__form > p {
    font-size: 12px;
}

.login-modal__link-text {
    text-decoration-line: underline;
}

.login-modal__link-text:hover {
    cursor: pointer;
}

.login-modal_error-info {
    color: red;
}

.login-modal__link {
    text-decoration: underline;
    color: blue;
}

.login-modal__link:hover {
    cursor: pointer;
}

.login-modal__loader {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.login-modal__loader-off {
    visibility: hidden;
    margin-top: 20px;
}

@media screen and (max-width: 576px) {
    .login-modal__button--close {
        top: 25px;
        right: 5px;
    }
}