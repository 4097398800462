.collapsible {
    display: block;
    margin: 0;
    padding: 0;
    grid-row-gap: 0;
}

.collapsible__content {
    margin-left: 10px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
    margin-bottom: 10px;
}

.collapsible--expanded {
    max-height: 100px;
}

.collapsible__header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible__header > h4 {
    margin: 0;
}

.collapsible__collapse-arrow {
    width: 15px;
    padding-right: 10px;
}

.collapsible__collapse-arrow--down {
    transform: rotate(180deg);
    padding-left: 10px;
    transition: 0.3s all ease;
}

.collapsible__collapse-arrow--up {
    transform: rotate(0deg);
    padding-left: 10px;
    transition: 0.3s all ease;
}